<template>
  <div class="container-fluid rounded bg-white p-5">
    <div class="row mb-3">
      <div class="col-12">
        <modal-actions @close="$router.go(-1)" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <span class="h2 text-secondary"> Nouveau comité</span>
        <div>
          <b-button pill type="submit" @click="onSubmit">Confirmer</b-button>
        </div>
      </div>
    </div>
    <div class="shdow">
      <div class="row p-0 m-0">
        <span class="h3 text-secondary">Informations générales</span>
      </div>
      <hr class="mt-1 mb-3 bg-secondary" />
      <div class="row m-0 p-0">
        <div>
          <div
            class="row m-0 p-0 align-items-center mb-2 col-sm-12 col-lg-auto"
          >
            <div
              style="max-width: 150px; min-width: 150px"
              class="d-flex align-items-center p-0 m-0 mr-2"
            >
              <b-form-group
                label="Nom: "
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              ></b-form-group>
            </div>
            <div style="flex: 1">
              <b-form-input
                type="text"
                placeholder="Nom"
                v-model="newComitte.name"
                :class="{
                  'is-invalid':
                    $v.newComitte.name.$error && $v.newComitte.name.$dirty,
                }"
              >
              </b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComitte.name.$dirty"
              >
                {{
                  !$v.newComitte.name.required
                    ? "Champ obligatoire"
                    : !$v.newComitte.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newComitte.name.$params.minLength.min} caractères.`
                    : !$v.newComitte.name.maxLength
                    ? `Le champ ne doit pas dépasser ${$v.newComitte.name.$params.maxLength.max} caractères`
                    : ""
                }}
              </span>
            </div>
          </div>
          <div
            class="row m-0 p-0 align-items-center mb-2 col-sm-12 col-lg-auto"
          >
            <div
              style="max-width: 150px; min-width: 150px"
              class="d-flex align-items-center p-0 m-0 mr-2"
            >
              <b-form-group
                label="Responsable:"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              ></b-form-group>
            </div>
            <div style="flex: 1">
              <v-select
                placeholder="Responsable"
                label="text"
                v-model="newComitte.responsable"
                :options="responsables"
                :reduce="(responsable) => responsable.value"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newComitte.responsable.$error &&
                    $v.newComitte.responsable.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComitte.responsable.$dirty"
              >
                {{
                  !$v.newComitte.responsable.required ? "Champ obligatoire" : ""
                }}
              </span>
            </div>
          </div>
          <div
            class="row m-0 p-0 align-items-center mb-2 col-sm-12 col-lg-auto"
          >
            <div
              style="max-width: 150px; min-width: 150px"
              class="d-flex align-items-center p-0 m-0 mr-2"
            >
              <b-form-group
                label="Périodicité:"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              ></b-form-group>
            </div>
            <div style="flex: 1">
              <v-select
                placeholder="Périodicité"
                label="text"
                v-model="newComitte.periodicite"
                :options="PERIODICITE"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newComitte.periodicite.$error &&
                    $v.newComitte.periodicite.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComitte.periodicite.$dirty"
              >
                {{
                  !$v.newComitte.periodicite.required ? "Champ obligatoire" : ""
                }}
              </span>
            </div>
          </div>
          <div
            class="row m-0 p-0 align-items-center mb-2 col-sm-12 col-lg-auto"
          >
            <div
              style="max-width: 150px; min-width: 150px"
              class="d-flex align-items-center p-0 m-0 mr-2"
            >
              <b-form-group
                label="Statut:"
                class="font-weight-bold"
                label-cols-sm="3"
                label-align-sm="left"
              ></b-form-group>
            </div>
            <div style="flex: 1">
              <v-select
                placeholder="Statut"
                label="text"
                v-model="newComitte.status"
                :options="status"
                style="padding-right: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newComitte.status.$error && $v.newComitte.status.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newComitte.periodicite.$dirty"
              >
                {{ !$v.newComitte.status.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </div>
        </div>
        <div style="width: 100%; flex: 1" class="ml-4 m-0">
          <b-form-group
            label="Description:"
            class="font-weight-bold"
            label-cols-sm="3"
            label-align-sm="left"
          ></b-form-group>
          <div class="W-100">
            <textarea
              class="form-control W-100"
              id="exampleFormControlTextarea1"
              v-model="newComitte.decription"
              rows="9"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <b-tabs class="tab-solid tab-solid-primary mt-5">
      <b-tab title="Membres permanents" active>
        <div class="d-flex mb-2">
          <span class="h4 text-secondary mr-auto my-auto"
            >Liste des Membres
          </span>

          <div class="d-flex justify-content-end">
            <customAddButton
              :disabled="true"
              text="Ajouter un membre"
            ></customAddButton>
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>

        <b-table
          :items="[]"
          :fields="tableFields"
          show-empty
          responsive
          bordered
          striped
        >
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Planning">
        <div class="d-flex mb-2">
          <span class="h4 text-secondary mr-auto my-auto"
            >Liste des Membres
          </span>

          <div class="d-flex justify-content-end">
            <b-button
              disabled
              variant="secondary"
              style="background-color: #198ae3; border-color: #198ae3"
              @click="addNewDateModal = !addNewDateModal"
            >
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter une date
            </b-button>
            <b-button
              disabled
              variant="secondary"
              style="background-color: #198ae3; border-color: #198ae3"
              @click="showCalendar = !showCalendar"
            >
              <i class="mdi mdi-plus mr-2"></i>
              Afficher calendrier
            </b-button>
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <div class="row">
          <div class="col-12">
            <b-table
              :fields="plannings.tableFields"
              show-empty
              bordered
              striped
            >
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
            </b-table>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <!-- add new date -->
    <!-- <div v-show="addNewDateModal" to="modal-xyz-500">
      <SubModal @close="addNewDateModal = !addNewDateModal">
        <div class="row">
          <div class="col-12 mb-3 d-flex">
            <p class="h2 mr-auto">Planification du comité: Comité n°1</p>

            <b-button variant="secondary" @click.prevent="onSubmitPlanning"
              >Confirmer</b-button
            >
            <hr />
          </div>
          <div class="col-12">
            <b-form-group label="" label-cols="0">
              <b-form-select
                v-model="planificationOption"
                :options="[
                  { value: 1, text: 'Planifier une date' },
                  { value: 2, text: 'Planifier une récurrence' },
                ]"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-12" v-if="planificationOption === 1">
            <div>
              <div class="row p-0 m-0 mb-2 flex-wrap">
                <b-form-group
                  style="flex: 1"
                  class="col-sm-12 col-md-6 m-0 p-0 flex-wrap"
                  label="Heure début:"
                  label-cols="4"
                >
                  <input type="time" class="form-control" />
                </b-form-group>
                <b-form-group
                  style="flex: 1"
                  class="col-sm-12 col-md-6 m-0 p-0 ml-2 flex-wrap"
                  label="Heure fin:"
                  label-cols="4"
                >
                  <input type="time" class="form-control" />
                </b-form-group>
              </div>
              <div class="col-md-6 col-12 p-0 m-0">
                <b-form-group label="Date:" label-cols="4">
                  <input type="date" class="form-control" />
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="planificationOption === 2">
            <div class="row">
              <div
                class="
                  col-12
                  d-flex
                  align-items-center
                  justify-content-between
                  flex-wrap
                  my-4
                "
              >
                <label class="d-block mb-0">Tous les</label>
                <b-form-checkbox-group
                  id="days-checkboxes"
                  class="d-flex align-items-center"
                  v-model="selectedDays"
                  :options="weekDays"
                  inline
                ></b-form-checkbox-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Date de début:" label-cols="4">
                  <input type="date" class="form-control" />
                </b-form-group>
                <b-form-group label="Heure de début:" label-cols="4">
                  <input type="time" class="form-control" />
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group label="Heure de fin:" label-cols="4">
                  <input type="time" class="form-control" />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </SubModal>
    </div> -->

    <!-- Show calendar -->
    <!-- <div v-show="showCalendar" to="modal-xyz-500">
      <SubModal @close="showCalendar = !showCalendar">
        <show-calendar />
      </SubModal>
    </div> -->

    <!-- Inscription & Decision -->
  </div>
</template>

<script>
// import SubModal from "@/components/custom/SubModal.vue";
import ModalActions from "@/components/custom/ModalActions.vue";

// import ShowCalendar from "./components/ShowCalendar.vue";
import { mapGetters } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import customAddButton from "@/components/custom/CustomAddButton";

export default {
  components: {
    // SubModal,
    customAddButton,
    ModalActions,
    // ShowCalendar,
  },
  data: () => ({
    newComitte: {
      name: "",
      decription: "",
      responsable: "",
      periodicite: "",
      status: "",
    },
    members: {},
    plannings: {
      tableItems: [{}],
      tableFields: [
        { key: "date", label: "Date", sortable: true },
        { key: "start_hour", label: "Heure de début", sortable: true },
        { key: "end_hour", label: "Heure de fin", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        {
          key: "inscription_decision",
          label: "Inscription & Décisions",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
    },
    addNewDateModal: false,
    showCalendar: false,
    showInscriptionDecision: false,
    planificationOption: 1,
    selectedDays: [],
    weekDays: [
      { value: "monday", text: "Lundi" },
      { value: "tuesday", text: "Mardi" },
      { value: "wednesday", text: "Mercredi" },
      { value: "thursday", text: "Jeudi" },
      { value: "friday", text: "Vendredi" },
    ],
    tableFields: [
      { key: "member", label: "Membre", sortable: true },
      {
        key: "organisation_attachment",
        label: "Organisation de rattachement",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    inscriptionDecision: {
      tableItems: [
        {
          folder_type: "Initiative",
          inscription_date: "10-10-2021",
          delivery: "Dossier X",
          inscription_status: "Soumise",
          decision: "Ajourné",
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "folder_type", label: "Type dossier", sortable: true },
        { key: "label", label: "Libellé", sortable: true },
        { key: "object_passage", label: "Objet passage", sortable: true },
        { key: "presentator", label: "Présentateur", sortable: true },
        { key: "inscription_date", label: "Date inscription", sortable: true },
        { key: "delivery", label: "Livrable", sortable: true },
        {
          key: "inscription_status",
          label: "Statut inscription",
          sortable: true,
        },
        { key: "guests", label: "Invités", sortable: true },
        { key: "decision", label: "Décision", sortable: true },
        {
          key: "decision_comment",
          label: "Commentaire décision",
          sortable: true,
        },
        { key: "actions", label: "", sortable: false },
      ],
    },
  }),
  validations: {
    newComitte: {
      name: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(20),
      },
      responsable: {
        required,
      },
      periodicite: {
        required,
      },
      status: {
        required,
      },
    },
  },
  methods: {
    // addNewDate() {},
    // addTag(newTag) {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   };
    //   this.options.push(tag);
    //   this.value.push(tag);
    // },
    // addInscription(i) {
    //   i.inscription_decision = this.INSCRIPTIONS.find(
    //     (inscription) => inscription.id === i.inscription_decision
    //   );

    //   this.newComitte.plannings.unshift(i);
    // },
    // onSubmitPlanning() {
    //   let tmp = {
    //     end_date: this.plannings.end_date,
    //     start_date: this.plannings.start_date,
    //     date: this.plannings.date,
    //   };
    //   this.$store.dispatch("planning/createPlanning", tmp);
    // },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let tmp = {
          name: this.newComitte.name,
          description: this.newComitte.decription,
          responsable: this.newComitte.responsable,
          periodicite: this.newComitte.periodicite,
          status: this.newComitte.status,
        };
        this.$store.dispatch("comite/createComite", tmp).then(() => {
          Swal.fire({
            title: "Le comité est bien créé !",
            type: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.$router.push({
                name: "show-committee",
                params: {
                  slug: this.COMITE.slug,
                  id: this.COMITE.id,
                  fromCreation: true,
                },
              });
            }
          });
        });
        // window.location.href = window.history.previous.href;
      }
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("comite/fetchAllComites");
    // this.$store.dispatch("planification/fetchAllPlannings");
    // this.$store.dispatch("manageComite/fetchAllInscription");
    this.$store.dispatch("status/fetchComiteStatus");
    this.$store.dispatch("status/fetchPeriodicite");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("comite", ["COMITES"]),
    // ...mapGetters("plannings", ["PLANNINGS"]),
    ...mapGetters("manageComite", ["INSCRIPTIONS"]),
    ...mapGetters("status", ["COMITE_STATUS"]),
    ...mapGetters("status", ["PERIODICITE"]),
    ...mapGetters("comite", ["COMITE"]),

    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    status() {
      return this.COMITE_STATUS;
    },
    periodicite() {
      return this.PERIODICITE;
    },

    // plannings() {
    //   return this.PLANNINGS.map((planning) => ({
    //     value: `/api/gouvernance/plannings/${planning.id}`,
    //     text: planning.name,
    //   }));
    // },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
#days-checkboxes > div > label > span {
  margin: 4px 0 0 0 !important;
  display: block !important;
}
.vs__search {
  color: #cfcfcf;
}
.vs__search,
.vs__search:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.4;
  font-size: 1em;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0 0;
  padding: 0 7px;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  z-index: 1;
}
</style>
